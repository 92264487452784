



















import firebase from 'firebase/app';
import * as firebaseui from 'firebaseui';
import { Component, Vue } from 'vue-property-decorator';
import { FirebaseUtils, SimpleUserInfo } from '../models/firebaseUtils';
import { Config } from '../models/config';

const uiConfig = {
  signInSuccessUrl: Config.stage === 'prod' ? Config.prodURL : Config.devURL,
  signInOptions: [
    // {
    //   provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
    //   requireDisplayName: true
    // },
    firebase.auth.GoogleAuthProvider.PROVIDER_ID
  ],
  signInFlow: 'popup'
};

@Component({})
export default class LoginView extends Vue {
  private status: 'unknown' | 'login' | 'logout' = 'unknown';
  private userInfo: SimpleUserInfo | null = null;

  private firebaseHelperUI = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());

  private mounted() {
    this.init();
  }

  private async init() {
    this.userInfo = await FirebaseUtils.getFirebaseUserStatus();
    if (this.userInfo === null) {
      this.prepareFirebaseLoginUI();
    } else {
      this.prepareFirebaseLoginUI();
      // TODO go another
    }
  }

  private prepareFirebaseLoginUI() {
    this.firebaseHelperUI.start('#firebaseui-auth-container', uiConfig);
  }

  private async logout() {
    this.status = 'unknown';
    await firebase.auth().signOut();
    this.status = 'logout';
    location.reload();
  }
}
